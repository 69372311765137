import React from 'react'

import './App.css'
import { QRCodeGenerator } from './components/QRCodeGenerator'

const App = () => (
  <div className="QRGeneratorApp">
    <QRCodeGenerator />
  </div>
)

export default App
