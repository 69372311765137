import React, { useState } from 'react'
import { Page, TextField, Layout, FormLayout } from '@shopify/polaris'

import { QRCodeView } from './QRCodeView'
import { DEFAULT_URL } from '../constants'

export const QRCodeGenerator = () => {
  const [url, setUrl] = useState<string>(DEFAULT_URL)

  return (
    <Page title="Teach Handwriting QR Generator">
      <Layout>
        <Layout.Section>
          <FormLayout>
            <TextField label="URL" type="text" value={url} onChange={setUrl} autoComplete='off' />
          </FormLayout>
        </Layout.Section>
        <QRCodeView url={url} />
      </Layout>
    </Page>
  )
}
