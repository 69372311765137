import { useCallback } from 'react'
import QRCode from 'qrcode'

import { MORRIS_SIZE_HEIGHT, MORRIS_SIZE_WIDTH, MORRIS_DEFAULT_SCALE_WIDTH } from '../constants'

import morris from './morris.png'

export const generateQRCode = () => {
  const generate = useCallback(async (canvas?: any, url?: string, width?: number, drawMorris?: boolean) => {
    if (!url || !canvas || !width || !morris || canvas == null) {
      console.log('Something is null', { url, canvas, width, morris })
      return
    }

    const options: QRCode.QRCodeRenderersOptions = {
      errorCorrectionLevel: 'H',
      width,
    }
    await QRCode.toCanvas(canvas, url, options)
    console.log('Done QR')
    if (!drawMorris) {
      return
    }
    const ctx = canvas.getContext('2d')
    const morrisImg = new Image()
    morrisImg.src = morris
    const loaded = function () {
      const morrisScale = width / MORRIS_DEFAULT_SCALE_WIDTH
      const dWidth = MORRIS_SIZE_WIDTH * morrisScale
      const dHeight = MORRIS_SIZE_HEIGHT * morrisScale
      const dx = (width - dWidth) / 2
      const dy = (width - dHeight) / 2
      ctx?.drawImage(morrisImg, 0, 0, MORRIS_SIZE_WIDTH, MORRIS_SIZE_HEIGHT, dx, dy, dWidth, dHeight)
      console.log('Done morris')
    }
    morrisImg.onload = loaded
    await loaded
  }, [])

  return generate
}
