import React, { useEffect, useRef, useCallback, useState } from 'react'
import { Button, TextField, Layout, Form, FormLayout, Checkbox } from '@shopify/polaris'

import { DEFAULT_SIZE, DEFAULT_FILENAME } from '../constants'
import { generateQRCode } from './generateQRCode'

type QRCodeProps = {
  url?: string
  size?: string
}

export const QRCodeView = (props: QRCodeProps) => {
  const { url } = props
  const [filename, setFilename] = useState<string>(DEFAULT_FILENAME)
  const [size, setSize] = useState<string>(DEFAULT_SIZE)
  const [morris, setMorris] = useState(false)
  const imgSize = size ? Number(size) : Number(DEFAULT_SIZE)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const generate = generateQRCode()

  useEffect(() => {
    generate(canvasRef.current, url, imgSize, morris)
  }, [url, imgSize, generate, morris])

  const onSave = useCallback(() => {
    console.log('On Save')
    const url = canvasRef.current?.toDataURL()
    if (!url) {
      return
    }
    const link = document.createElement('a')
    link.download = `${filename}.png`
    link.href = url
    link.click()
  }, [filename])

  return (
    <>
      <Layout.Section>
        <canvas ref={canvasRef} />
      </Layout.Section>
      <Layout.Section secondary>
        <Form onSubmit={onSave}>
          <FormLayout>
            <TextField label="Size" type="number" value={size} onChange={setSize} min={100} max={2000} step={100} suffix="px" autoComplete='off' />
            <TextField label="Filename" type="text" value={filename} onChange={setFilename} suffix=".png" autoComplete='off' />
            <Checkbox label="Add Morris" checked={morris} onChange={setMorris}/>
            <Button submit>Save</Button>
          </FormLayout>
        </Form>
      </Layout.Section>
    </>
  )
}
